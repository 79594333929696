import { abortNavigation, createError, defineNuxtRouteMiddleware } from 'nuxt/app';
import type { RouteLocationNormalized } from 'vue-router';

import { STATUS_CODE } from '@/constants/error.const';
import useMyInfoStore from '@/stores/my-info.store';
import type { ErrorResponse } from '@/types/common/common.type';
import { isDirectAccess } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const myInfoStore = useMyInfoStore();
    const { params } = to;
    try {
      await myInfoStore.fetchGroupJoinedDetail(params.id as string);
    } catch (error) {
      const err: ErrorResponse = error as ErrorResponse;
      const { name: toName } = to;
      const { name: fromName } = from;

      if (err.statusCode === STATUS_CODE.GROUP_NOT_FOUND) {
        if (isDirectAccess(toName, fromName)) {
          throw createError({
            statusCode: STATUS_CODE.GROUP_NOT_FOUND,
            message: 'Group not found'
          });
        }
        return abortNavigation();
      }
    }
  }
);
